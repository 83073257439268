<template>
  <div class="shadow_end leave" @click="CancelLeaving()">
    <div class="frame">
      <div class="skull img_wait"><img src="../assets/pistons_svg.svg"></div>
      <div class="money_box">
        <span>Want to leave?</span>
      </div>
      <div class="yes continue_button" @click="ConfirmLeave()"></div>
    </div>
  </div>
</template>

<style>
.yes .continue_button{
  background-image: url("../assets/clans/yes.svg");
}
</style>

<script>
import axios from "axios";

export default {
  props: ["user_type", "ClanId"],
  methods: {
    CancelLeaving() {
      setTimeout(() => {this.$emit('CancelLeaving')}, 20);
    },

    ConfirmLeave() {
      if (this.user_type === 'member') {
        this.LeaveClanRequest();
        this.$emit("LeaveClanConfirmed");
      } else if (this.user_type === 'owner'){
        this.DeleteClanRequest();
        this.$emit("LeaveClanConfirmed");
      }
    },

    // api

    async LeaveClanRequest() {
      this.initData = window.Telegram.WebApp.initData;
      let url = "https://cryptocowboy.ru/api/v1/clans/leave";

      axios.defaults.headers.common["X-User-Data"] = `${this.initData}`;

      await axios
        .get(url)
        .then(() => {
          this.$emit("LeaveClanConfirmed");
        })
        .catch((error) => {console.log("on " + url, error);});
    },

    async DeleteClanRequest() {
      this.initData = window.Telegram.WebApp.initData;
      let url = "https://cryptocowboy.ru/api/v1/clans/" + this.ClanId + "/delete";

      axios.defaults.headers.common["X-User-Data"] = `${this.initData}`;

      await axios
        .post(url, {
          "clan_id": this.ClanId,
        })
        .then(() => {
          console.log("success");
        })
        .catch((error) => {console.log("on " + url, error);});
    }
  }
}
</script>