<template>
    <div class = "box_1">
        <img class = "wood" src = "../assets/Upgrade_assets/upgrade.png">
        <div class = "box_2">
            <div class="text">
                Loading...
            </div>
        </div>
    </div>
</template>

<style scoped>
@import '../styles/LoadingStyles.css';
</style>

<script>
</script>