<template>
  <Loading v-if="loader_visible && true"></Loading>

  <LeaveClan @CancelLeaving="CancelLeaving" @LeaveClanConfirmed="LeaveClanConfirmed" :user_type="clan.type"
    :ClanId="clan.id" v-if="leave_clan_visible"></LeaveClan>
  <ChangeClanName :clanID="this.LocalClanId" @CancelEditingClan="CancelEditingClan" @SetClanName="SetClanName"
    v-if="change_clan_name_visible"></ChangeClanName>

  <img class="clans_info_page__board" src="../assets/clans/Frame 151.svg">


  <div class="clans_info_page__board_header">
    <div class="clans_info_page__board_header_line_1">{{ clan.name }}</div>

    <div class="clans_info_page__board_header_line_2">
      <span>{{ clan.silver }} SILVER </span>
    </div>

    <div class="clans_info_page__board_header_line_3">
      <img class="btn" @click="ReturnToClanList()" src="../assets/TopBar_assets/back.svg" />

      <span>{{ clan.number_of_members }} MEMBERS</span>

      <div>
        <img class="btn" v-if="clan.type === 'user' && user_status === 'not_in_clan'" @click="JoinClan()"
          src="../assets/clans/btn_plus.svg" />
        <img class="btn" v-if="clan.type === 'owner'" @click="EditClan()" src="../assets/clans/change.svg" />
        <img class="btn" v-if="clan.type === 'member' || clan.type === 'owner'" @click="LeaveClan()"
          src="../assets/clans/leave_clan_btn.svg" />
      </div>
    </div>
  </div>


  <div class="clans_info_content">
    <hr color="#37251f" width="300px">

    <div class="clans_info_content__list">
      <div class="clans_info_content__list_item" v-for="member in clan_members" v-bind:key="member">

        <div class="clans_info_content__list_item_img">
          <img v-if="member.photo_url === ''" src="../assets/MoneyImages/person1.svg" />
          <img v-else :src="member.photo_url" />
        </div>

        <div class="clans_info_content__list_item_content">
          <p style="font-size: large;">{{ member.username }}</p>
          <p>{{ member.silver }} SILVER</p>
        </div>

        <v-spacer></v-spacer>

        <div class="clans_info_content__list_item_horse_lvl">
          <p>{{ member.horse_lvl }} LVL</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@import url('../styles/ClansView__ClanInfo_styles.css');

.back_btn {
  position: absolute;
  top: 25px;
  left: -10px;
  width: 35px;
  z-index: 19;
}
</style>

<script>
/* eslint-disable */
import axios from "axios";
import LeaveClan from "@/components/ClansView__LeaveClan.vue";
import ChangeClanName from "@/components/ClansView__ChangeClanName.vue";
import Loading from "@/components/LoadingVue.vue";


export default {
  components: {
    LeaveClan,
    ChangeClanName,
    Loading,
  },
  props: ["clanID", "user_status"],
  data() {
    return {
      loader_visible: true,
      change_clan_name_visible: false,
      leave_clan_visible: false,

      refreshed: 0,
      LocalClanId: 0,

      clan: {
        // "id": 0,
        // "name": "Test Clan",
        // "silver": 100000,
        // "number_of_members": 3,
        // "link": "",
        // "is_open": false,
        // "owner": {
        //   "username": "niumandzi",
        //   "silver": 10000,
        //   "horse_lvl": 12
        // },
        // "type": "member"
      },

      clan_members: [
        // {
        //   "id": 1,
        //   "username": "user 1",
        //   "photo_url": "",
        //   "silver": 100,
        //   "horse_lvl": 5
        // },
        // {
        //   "id": 2,
        //   "username": "user 2",
        //   "photo_url": "",
        //   "silver": 200,
        //   "horse_lvl": 10
        // },
        // {
        //   "id": 3,
        //   "username": "user 3",
        //   "photo_url": "",
        //   "silver": 300,
        //   "horse_lvl": 15
        // },
        // {
        //   "id": 1,
        //   "username": "user 1",
        //   "photo_url": "",
        //   "silver": 100,
        //   "horse_lvl": 5
        // },
        // {
        //   "id": 2,
        //   "username": "user 2",
        //   "photo_url": "",
        //   "silver": 200,
        //   "horse_lvl": 10
        // },
        // {
        //   "id": 3,
        //   "username": "user 3",
        //   "photo_url": "",
        //   "silver": 300,
        //   "horse_lvl": 15
        // },
        // {
        //   "id": 1,
        //   "username": "user 1",
        //   "photo_url": "",
        //   "silver": 100,
        //   "horse_lvl": 5
        // },
        // {
        //   "id": 2,
        //   "username": "user 2",
        //   "photo_url": "",
        //   "silver": 200,
        //   "horse_lvl": 10
        // },
        // {
        //   "id": 3,
        //   "username": "user 3",
        //   "photo_url": "",
        //   "silver": 300,
        //   "horse_lvl": 15
        // },
        // {
        //   "id": 1,
        //   "username": "user 1",
        //   "photo_url": "",
        //   "silver": 100,
        //   "horse_lvl": 5
        // },
        // {
        //   "id": 2,
        //   "username": "user 2",
        //   "photo_url": "",
        //   "silver": 200,
        //   "horse_lvl": 10
        // },
        // {
        //   "id": 3,
        //   "username": "user 3",
        //   "photo_url": "",
        //   "silver": 300,
        //   "horse_lvl": 15
        // },
      ],
    };
  },
  watch: {
    refreshed() {
      if (this.refreshed === 2) {
        this.refreshed = 0;
      }
    },
  },
  methods: {
    JoinClan() {
      this.JoinClanRequest();
    },

    EditClan() {
      this.change_clan_name_visible = true;
    },

    CancelEditingClan() {
      this.change_clan_name_visible = false;
    },

    SetClanName(clan_name) {
      this.clan.name = clan_name;
    },

    LeaveClan() {
      this.leave_clan_visible = true;
    },

    LeaveClanConfirmed() {
      console.log("LeaveClan");
      this.clan.type = 'user';
      this.$emit('SetUserStatus', 'not_in_clan');
      this.$emit('ChangePage', { ClanID: 0 });
    },

    CancelLeaving() {
      console.log('Cancelled');
      this.leave_clan_visible = false;
    },

    ReturnToClanList() {
      this.$emit('ChangePage', { ClanID: 0 });
    },

    async RefreshData() {
      this.GetClanInfoRequest(this.clanID).then(() => this.refreshed += 1);

      let data = {
        "clan_id": this.clanID,
        "page": 0,
        "list_amount": 5
      };
      this.GetClanMembersRequest(data).then(() => this.refreshed += 1);
    },

    //api

    async JoinClanRequest() {
      this.initData = window.Telegram.WebApp.initData;
      let url = "https://cryptocowboy.ru/api/v1/clans/" + this.clanID + "/join";

      axios.defaults.headers.common["X-User-Data"] = `${this.initData}`;

      await axios
        .post(url)
        .then(() => {
          this.clan.type = 'member';
          this.$emit('SetUserStatus', 'in _clan');
          this.RefreshData();
        })
        .catch((error) => { console.log("on " + url, error); });
    },

    async GetClanInfoRequest(clanID) {
      this.initData = window.Telegram.WebApp.initData;
      let url = "https://cryptocowboy.ru/api/v1/clans/info/" + clanID;
      this.LocalClanId = clanID;

      axios.defaults.headers.common["X-User-Data"] = `${this.initData}`;

      await axios
        .get(url)
        .then((response) => {
          this.clan = response.data;
        })
        .catch((error) => { console.log("on " + url, error); });
    },

    async GetClanMembersRequest(data) {
      this.initData = window.Telegram.WebApp.initData;
      let url = "https://cryptocowboy.ru/api/v1/clans/members";

      axios.defaults.headers.common["X-User-Data"] = `${this.initData}`;

      await axios
        .post(url, data)
        .then((response) => {
          this.clan_members = response.data;
        })
        .catch((error) => { console.log("on " + url, error); });
      this.loader_visible = false;
    },
  },
}
</script>