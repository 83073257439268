<template>
  <div class="nav">
    <div class="link link1" @click="link_timeout('ClansView', 100)"><img src = "../assets/Navigation_assets/f1.svg"></div>
    <div class="link link2" @click="link_timeout('MainMenuView', 100)"><img src = "../assets/Navigation_assets/f2.svg"></div>
    <div class="link link3" @click="link_timeout('StatisticsView', 100)"><img src = "../assets/Navigation_assets/f3.svg"></div>
  </div>
</template>

<style>
@import "../styles/NavigationStyles.css";
</style>

<script>

export default {
  data() {},
  methods: {
    //router
    link_timeout(link, timeout) {
      setTimeout(() => {
        this.$emit("VisibilityToggler", {ComponentName: "DonatePage", visible: false});
        if (link === "ClansView"){
          this.$emit("RefreshIfYouInClan");
        }
        this.$emit("setTab", link);
      }, timeout);
    },
  },
};
</script>
