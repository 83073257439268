<template>
  <div class="centring shadow_end leave">
    <div class = "fictive_leaving"></div>
    <div class="frame">
      <div class="skull img_wait enter_name">
        <img src="../assets/clans/add_clan.svg"/>
      </div>
      <div class="cross_but"><img src = "../assets/clans/button_cross.svg" @click="Cancel()"></div>
      <div class="money_box">
        <div class="m_rec">
          <input v-model="clan_name" placeholder="NAME A CLAN"/>
          <div class="s_m_rec"></div>
        </div>
      </div>

      <div class="continue_button" @click="ConfirmName()"></div>
    </div>
  </div>

</template>

<style>

.fictive_leaving{
  width: 100vw;
  height: 100vh;
  /* background-color: red; */
  position: absolute;
  z-index: 23;
}

</style>

<script>
import axios from "axios";

export default {
  data() {
    return {
      clan_name: "",
    }
  },
  methods: {
    Cancel(){
      this.$emit("CreateClanCancel");
    },

    ConfirmName() {
      let data = {
        "name": this.clan_name,
        "is_open": true
      };
      this.CreateClanRequest(data);
    },

    // api

    async CreateClanRequest(data){
      this.initData = window.Telegram.WebApp.initData;
      let url = "https://cryptocowboy.ru/api/v1/clans/create";

      axios.defaults.headers.common["X-User-Data"] = `${this.initData}`;

      await axios
        .post(url, data)
        .then((response) => {
          this.$emit("CreateClanSuccess", response.data.id);
          this.$emit("CreateClanCancel");
        })
        .catch((error) => {console.log("on " + url, error);});
    },
  },
};
</script>