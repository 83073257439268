<template>
  <div class="clans_view_container">
    <img class="clans_view_bg_img" src="../assets/Upgrade_assets/upgrade.png"/>
    <Clanslist @hide-navbar="$emit('hide-navbar')"
               @show-navbar="$emit('show-navbar')" ref="ClansListComponent" :user_status="user_status" :refresh="Refresh" @ChangePage="ChangePage" v-if="page_type === 0"></Clanslist>
    <ClanInfo ref="ClanInfoComponent" @SetUserStatus="SetUserStatus" @ChangePage="ChangePage" :clanID="clanID"
      :user_status="user_status" v-if="page_type == 1"></ClanInfo>
  </div>
</template>

<style>
.clans_view_container {
  height: 100vh;
  width: 100vw;
}

.clans_view_bg_img {
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0px;
}


.weight {
  font-weight: normal;
  letter-spacing: 2px;
}

.centring {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.cross_but {
  position: absolute;
  width: 30px;
  height: 30px;
  margin-left: 160px;
  margin-bottom: 100px;
  z-index: 20;
}

.cross_but:active {
  -webkit-animation: Scale 0.1s;
  animation: Scale 0.1s;
  animation-timing-function: ease-in-out;
}


.scroll-add {
  overflow-y: scroll;
  max-height: 500px;
  margin-bottom: 90px;
  z-index: 1;
}

.frame {
  position: relative;
  z-index: 24;
}

.m_rec input {
  outline-width: 0;
  color: white;
  top: 5px;
  position: relative;
  width: 100px;
  height: 20px;
  text-align: center;
}

.clans {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.create_clan {
  top: 0;
}

.white {
  color: white;
}

.make-clan-frame {
  width: 350px;
  height: 75px;
  position: absolute;
}

.make_clan form {
  position: relative;
  height: 30px;
  top: 40px;
  border: 2px red;
}

.make_clan input {
  color: white;
  width: 220px;

  border-bottom: 1px solid rgba(135, 105, 83, 1);
}

input[type="text"]:focus {
  outline-width: 0;
  color: white;
}

input:active,
:hover,
:focus {
  outline: 0;
  outline-offset: 0;
}

.make_clan {
  width: 100vw;
  height: 75px;
  display: flex;
  justify-content: center;
  margin-top: 65px;
  z-index: 2;
}

.make_clans_box {
  width: 310px;
  height: 75px;
  display: flex;
  justify-content: space-between;
}

.make-clan-small-box {
  height: 50px;
  width: 75px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-self: end;
}

.make-clan-small-box .mini_button {
  align-self: center;
}

.leave {
  z-index: 24;
  position: absolute;
}

.leave .continue_button {
  left: 50px;
  top: 110px;
  position: absolute;
}

.leave span {
  font-size: 20px;
  width: 200px;
  top: 60px;
  position: absolute;
}

.leave .m_rec {
  left: 40px;
  top: -10px;
}

.continue_button {
  background-image: url("../assets/clans/btn_yes.svg");
}

.enter_name {
  top: -10px;
}

.clans-page .make-clan-frame {
  height: 150px;
}

.clans-page-box {
  width: 350px;
  height: 150px;
  display: flex;
  flex-direction: column;
  position: relative;
  color: white;
}

.clans-page-box span {
  position: absolute;
}

.clans-page-box span:first-child {
  font-size: 24px;
  top: 20px;
  left: 70px;
  width: 200px;
  text-align: center;
}

.clans-page-box span:nth-child(2) {
  top: 60px;
  left: 25px;
  font-size: 12px;
}

.clans-page-box span:nth-child(3) {
  top: 60px;
  left: 240px;
  font-size: 12px;
}

.clans-page-box span:nth-child(4) {
  top: 85px;
  left: 25px;
  font-size: 18px;
}

.clans-page-box span:nth-child(5) {
  top: 105px;
  left: 25px;
  font-size: 12px;
}

.clans-page-box span:nth-child(6) {
  top: 105px;
  left: 150px;
  font-size: 12px;
}

.clans-page-box .mini_button {
  margin-top: 98px;
  margin-left: 300px;
  z-index: 25;
}

@keyframes Scale {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.9);
  }
}

@import "../styles/ClansViewStyles.css";
</style>

<script>
import Clanslist from "@/components/ClansView__Clanslist.vue";
import ClanInfo from "@/components/ClansView__ClanInfo.vue"
import axios from "axios";

export default {
  components: {
    Clanslist,
    ClanInfo,
  },
  data() {
    return {
      page_type: 0,
      changed: 0,
      user_status: "not_in_clan",
      clanID: 0,

      delay_to_mount_component: 150,
    };
  },
  mounted() {
    this.Refresh();
  },
  watch: {
    //from components
    changed() {
      this.page_type = (this.page_type + 1) % 2;
      this.$emit("component_loading");
      this.RefreshComponents();
    }
  },
  methods: {
    //from components
    ChangePage(data) {
      console.log("change", data);
      this.clanID = data.ClanID;
      this.changed += 1;
    },

    SetUserStatus(status) {
      this.user_status = status;
    },

    // from this
    Refresh() {
      this.CheckUserStatus().then(() => {
        this.RefreshComponents();
      });
    },

    RefreshIfYouInClan(){
      if(this.page_type === 1){
        this.clanID = 0;
        this.changed += 1;
      } else{
        this.Refresh();
      }
    },

    async RefreshComponents() {
      if (this.page_type === 1) {
        setTimeout(() => {
          this.$refs.ClanInfoComponent.RefreshData()
            this.$emit("component_loaded", { name: "ClansView" });
        }, this.delay_to_mount_component)
      } else {
        setTimeout(() => {
          this.$refs.ClansListComponent.RefreshData()
            this.$emit("component_loaded", { name: "ClansView" });

        }, this.delay_to_mount_component);
      }
    },

    // api

    async CheckUserStatus() {
      this.initData = window.Telegram.WebApp.initData;
      let url = "https://cryptocowboy.ru/api/v1/users";

      axios.defaults.headers.common["X-User-Data"] = `${this.initData}`;

      await axios
        .get(url)
        .then((response) => {
          if (response.data.clan_id > 0) {
            this.clanID = response.data.clan_id;
            this.page_type = 1;
            this.user_status = "on_clan";
          } else {
            this.page_type = 0;
            this.user_status = "not_in_clan";
          }
        })
        .catch((error) => { console.log("on " + url, error); });
    }
  },
};
</script>
