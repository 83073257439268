<template>
  <!--clan create modal window -->
  <Loading v-if="loader_visible && true"></Loading>

  <CreateClan @CreateClanCancel="CreateClanCancel" @CreateClanSuccess="CreateClanSuccess"
    v-if="create_clan_page_visible"></CreateClan>
    
  <img class="clans_list_page__board" src="../assets/clans/Frame 151.svg">

  <img class="clans_list_page__board_title" src="../assets/clans/new_Pistols_Icon.svg" />

  <div class="clans_list_content">

    <div class="clans_list_content__search">
      <form class="clans_list_content__search_form" @submit.prevent="GetClansSearch">
        <input @focus="$emit('hide-navbar')" :class="{ 'white-text': !isFocused }"
               @blur="$emit('show-navbar'); isFocused = false " type="text" v-model="searchNameClan" placeholder="ENTER CLAN NAME" /> <br />
      </form>

      <v-spacer></v-spacer>

      <div class="clans_list_content__search_btns">
        <img class="btn" style="margin-right: 10px" @click="GetClansSearch" src="../assets/clans/search.svg" />
        <img class="btn" v-if="user_status === 'not_in_clan'" @click="CreateClan()" src="../assets/clans/plus.svg" />
        <img class="btn" v-else @click="refresh" src="../assets/clans/return_to_clan.svg" />
      </div>
    </div>

    <hr color="#37251f" width="300px">

    <div class="clans_list_content__list">
      <div class="clans_list_content__list_item" v-for="clan in clans_list" v-bind:key="clan"
        v-on:click="VisitClanPage(clan.id)">
        <div class="clans_list_content__list_item_img"><img style="width: 60px;height: 60px;"
            src="../assets/MoneyImages/clan_img.png" /></div>
      
        <div class="clans_list_content__list_item_content">
          <p style="font-size: large;">{{ clan.name }}</p>
          <p>{{ clan.silver }} silver</p>
          <p>{{ clan.number_of_members }} members</p>
        </div>
        <v-spacer></v-spacer>
        <div class="clans_list_content__list_item_clan_owner">
          <p>{{ clan.owner_username }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@import url('../styles/ClansView__ClanList_styles.css');

@input: :placeholder {
  color: rgb(255 255 255);
}
.white-text {
  color: white;
}
</style>

<script>
import axios from "axios";
import CreateClan from "@/components/ClansView__CreateClan.vue";
import Loading from "@/components/LoadingVue.vue";

export default {
  components: {
    Loading,
    CreateClan,
  },
  props:{
    user_status:{
      type: String,
    },
    refresh:{
      type: Function,
    },
  },
  data() {
    return {
      isFocused: false,
      loader_visible: true,
      create_clan_page_visible: false,
      refreshed: true,
      searchNameClan: "",
      clans_list: [
        // {
        //   "id": 1,
        //   "name": "Test Clan",
        //   "owner_username": "niumandzi",
        //   "silver": 100000,
        //   "number_of_members": 1,
        //   "type": ""
        // },
        //
        // {
        //   "id": 1,
        //   "name": "Test Clan",
        //   "owner_username": "niumandzi",
        //   "silver": 100000,
        //   "number_of_members": 1,
        //   "type": ""
        // },
      ]
    }
  },
  mounted() {
    this.GetClansList();
  },
  watch: {
    searchNameClan(val) {
      if (val.trim() === "") {
        this.GetClansSearch();
      }
    }
  },
  methods: {
    CreateClan() {
      this.create_clan_page_visible = true;
    },

    CreateClanCancel() {
      this.create_clan_page_visible = false;
    },

    CreateClanSuccess(clanID) {
      let page_type = 2;
      this.$emit('ChangePage', { page_type, clanID });
    },

    Search() { },

    VisitClanPage(ClanID) {
      this.$emit('ChangePage', { ClanID: ClanID });
    },

    async RefreshData() {
      this.GetClansList().then(() => {
        this.refreshed = true;
      });
    },

    //api

    async GetClansList() {
      let data = {
        "page": 0,
        "list_amount": 10,
      };
      await this.GetClansListRequest(data);
    },

    async GetClansListRequest(data) {
      this.initData = window.Telegram.WebApp.initData;
      let url = "https://cryptocowboy.ru/api/v1/clans";
      axios.defaults.headers.common["X-User-Data"] = `${this.initData}`;

      await axios
        .post(url, data)
        .then((response) => {
          this.clans_list = response.data;
        })
        .catch((error) => { console.log("on " + url, error); });
      this.loader_visible = false
    },

    async GetClansSearch() {
      switch (true) {
        case this.searchNameClan === "":
          try {
            await this.GetClansList();
          } catch (error) {
            console.error("Error in GetClansList:", error);
          }
          break;

        default:
          try {
            this.initData = window.Telegram.WebApp.initData;
            axios.defaults.headers.common["X-User-Data"] = `${this.initData}`;

            const url = `https://cryptocowboy.ru/api/v1/clans/search/${this.searchNameClan}`;
            console.log("Searching for:", this.searchNameClan);

            const response = await axios.get(url, {
              params: { name: this.searchNameClan }
            });

            this.clans_list = response.data;
          } catch (error) {
            console.error(`Error fetching :`, error);
          }
          break;
      }
    }
  }
}
</script>